<template>
  <div class="container">
    <div class="main">
      <div class="area">
        <span style="font-weight: bold; color: #333">地理位置：</span>
        <span
          v-for="(item, index) in areaList"
          :key="index"
          class="area_item"
          :class="item.active ? 'active' : ''"
          @click="changeArea(index, item.area_id)"
        >
          {{ item.area_name }}
        </span>
      </div>
      <div class="card_list">
        <div
          class="card_list_item"
          v-for="(item, index) in houseList"
          :key="index"
          @click="showDetail(item.fx_id)"
        >
          <div class="img_out">
            <img v-lazy="item.cover_picture?.path" />
          </div>
          <div class="card_footer">
            <span class="title">{{ item.fx_name }}</span>
            <div class="room_info">
              <div class="room_info_l">
                <span>{{ item.acreage_min }}-{{ item.acreage_max }}㎡</span>
                <span>|</span>
                <span>{{ item.rental_type == 1 ? "整租" : "合租" }}</span>
              </div>
              <div class="room_info_r">
                <span class="price"
                  >{{ item.price_min }}-{{ item.price_max }}</span
                >
                <span class="unit">元/月</span>
              </div>
            </div>
            <div class="tag">
              <div
                class="tag_item"
                v-for="(item2, index2) in item.appliances"
                :key="index2"
              >
                {{ item2 }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页组件 -->
      <paginate
        :page-count="total"
        :click-handler="changPage"
        :prev-text="'上一页'"
        :next-text="'下一页'"
        :container-class="'className'"
        :page-class="'page-item'"
      >
      </paginate>
    </div>
    <room-detail ref="roomDetail" :isShow="isShow" />
    <loading v-if="isLoading" />
  </div>
</template>
<script>
import roomDetail from "@/components/roomDetail";
import { getArea, getHouse } from "@/api/lookHouse";
import paginate from "vuejs-paginate";
export default {
  name: "lookHouse",
  components: {
    roomDetail,
    paginate,
  },
  data() {
    return {
      query: {
        page: 1,
        per_page: 9,
        area_id: null,
      },
      total: 0,
      areaList: [],
      houseList: [],
      isShow: false,
      isLoading: false,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.isLoading = true;
      const area = getArea();
      const house = getHouse(this.query);
      Promise.all([area, house])
        .then((res) => {
          //处理地区
          res[0].data.areas.forEach((item) => {
            item.active = false;
          });
          this.areaList = res[0].data.areas;
          //处理房源
          this.houseList = res[1].data;
          this.total = Math.ceil(res[1].total / this.query.per_page);
        })
        .finally((_) => {
          this.isLoading = false;
        });
    },
    async initHouse() {
      const res = await getHouse(this.query);
      this.houseList = res.data;
      this.total = Math.ceil(res.total / this.query.per_page);
    },
    changeArea(i, id) {
      this.areaList.forEach((item, index) => {
        if (i == index) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
      this.query.area_id = id;
      this.query.page = 1;
      this.initHouse();
    },
    showDetail(id) {
      this.isShow = true;
      this.$refs.roomDetail.detailId = id;
      this.$refs.roomDetail.translateX = 0;
      this.$refs.roomDetail.current = 0;
      this.$refs.roomDetail.initData();
    },
    //分页
    changPage(pageNum) {
      this.query.page = pageNum;
      this.initHouse();
    },
  },
};
</script>
<style scoped lang="scss">
$width: 1600px;
.container {
  box-sizing: border-box;
  width: 100vw;
  height: auto;
  .main {
    width: $width;
    height: auto;
    margin: 0 auto;
    padding: 0 50px;
    box-sizing: border-box;
    .area {
      width: 100%;
      height: 90px;
      display: flex;
      align-items: center;
      column-gap: 42px;
      border-bottom: 1px solid #eeeeee;
      font-size: 18px;
      &_item {
        cursor: pointer;
      }
      .active {
        color: #ffa71d;
        transition: all 0.3s ease;
      }
    }
    .card_list {
      margin: 30px 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
      &_item {
        width: 482px;
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #eeeeee;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
        }
        .card_footer {
          padding: 20px;
          box-sizing: border-box;
          color: #333333;
          .title {
            font-size: 22px;
            &:hover {
              color: #ffa71d;
            }
          }
          .room_info {
            display: flex;
            justify-content: space-between;
            margin: 20px 0;
            align-items: center;
            &_l {
              display: flex;
              column-gap: 15px;
              color: #666666;
              font-size: 16px;
            }
            &_r {
              .price {
                font-size: 28px;
                color: #ff9517;
                font-weight: bold;
              }
            }
          }
          .tag {
            display: flex;
            column-gap: 10px;
            flex-wrap: wrap;
            &_item {
              padding: 2px 6px;
              border: 1px solid #d9d9d9;
              border-radius: 4px;
              font-size: 14px;
              color: #666666;
            }
          }
        }
        .img_out {
          width: 100%;
          height: 341px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            border-radius: 10px 10px 0 0;
            object-fit: cover;
            transition: all 0.3s ease;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
}
.className {
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
  padding: 20px 0;
  box-sizing: border-box;
  ::v-deep li {
    padding: 2px 8px;
    box-sizing: border-box;
    border: 1px solid #eeeeee;
    color: #666;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+/Edge */
    user-select: none;
  }
  ::v-deep .page-item {
    width: 30px;
    height: 30px;
  }
  ::v-deep .active {
    background-color: #ffa71d;
    color: #fff;
  }
}
</style>